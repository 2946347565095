import { useCallback, useEffect } from 'react';
import { Box, Button, Card, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { SnackbarContent, useSnackbar } from 'notistack';

import { ReactComponent as Unread } from '@assets/chat_message_waiting.svg';

import { useChatContext } from '@contexts/ChatContext';

import {
  useMaybePromise,
  useObservable,
  usePageVisibility,
  usePrevious,
} from '@hooks/index';

import { ChatView } from '@services/Chat';
import { useExtendedTranslation } from '@services/i18nService';
import { dismissChatNotification } from '@services/PatronService';

import { PatronId } from '@typings/IPatron';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'left',
    padding: '0.5rem 0.5rem 0 0.5rem',
  },
  messageIcon: {
    verticalAlign: 'middle',
  },
  messageText: {
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: '0.5rem',
  },
  tapButton: {
    color: '#027AC5',
  },
}));

const messageHasInitial = (s: string): boolean => _.get(s, 2) === '|';
const removeMaybeInitial = (s: string): string =>
  messageHasInitial(s) ? _.join(_.slice(s, 3), '') : s;

export function useMessageNotification(
  patronId: PatronId,
  chat?: Promise<ChatView>
) {
  const isVisible = usePageVisibility();
  const chatUnwrapped = useMaybePromise(chat);
  const hasUnreadMessages = useObservable(
    false,
    chatUnwrapped?.hasUnreadMessages
  );

  const messagesList = useObservable([], chatUnwrapped?.messages);
  const previous = usePrevious(messagesList);

  const t = useExtendedTranslation();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    showMessaging,
    updateShowMessaging,
    lastNotifiedMessageId,
    updateLastNotifiedMessageId,
  } = useChatContext();

  const getContent = useCallback(
    (messageToDisplay: string) => {
      return (
        <SnackbarContent className={classes.root}>
          <Card className={classes.card}>
            <Typography variant="body1">
              <SvgIcon component={Unread} className={classes.messageIcon} />
              {`  ${t('messagenotif.newmessage')}`}
            </Typography>
            <Typography variant="body1" className={classes.messageText}>
              &quot;{removeMaybeInitial(messageToDisplay)}&quot;
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() => updateShowMessaging(true)}
                variant="text"
                className={classes.tapButton}
              >
                {t('messagenotif.respond')}
              </Button>
            </Box>
          </Card>
        </SnackbarContent>
      );
    },
    [classes, t, updateShowMessaging]
  );

  useEffect(() => {
    if (!showMessaging && hasUnreadMessages) {
      const lastMessage = _.last(messagesList);
      const lastMessageId = _.get(lastMessage, 'id', '');
      if (
        lastMessage &&
        _.size(previous) < _.size(messagesList) &&
        lastMessageId !== lastNotifiedMessageId
      ) {
        const messageToDisplay = _.get(lastMessage, 'message', '');

        enqueueSnackbar('', {
          key: messageToDisplay,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          content: getContent(messageToDisplay),
          preventDuplicate: true,
          persist: false,
        });
        updateLastNotifiedMessageId(lastMessageId);

        if (isVisible) {
          dismissChatNotification(patronId);
        }
      }
    } else if (showMessaging) {
      closeSnackbar();
    }
  }, [
    closeSnackbar,
    enqueueSnackbar,
    getContent,
    hasUnreadMessages,
    isVisible,
    lastNotifiedMessageId,
    messagesList,
    patronId,
    previous,
    showMessaging,
    updateLastNotifiedMessageId,
  ]);
}
