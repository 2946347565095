import { MouseEventHandler, useMemo } from 'react';
import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';

import { SinglePanelWithMessaging } from '@components';

import InQStyleGuide from '@constants';

import { useExtendedTranslation } from '@services/i18nService';

import IPatron from '@typings/IPatron';

const LeaveQueueButton = withStyles({
  root: {
    padding: 0,
  },
  label: {
    color: InQStyleGuide.blue.bright,
    fontWeight: 600,
    textDecoration: 'underline',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: '0.875rem',
  },
})(Button);

const baseStyle = {
  root: {
    color: InQStyleGuide.blue.medium,
    fontSize: '0.875rem',
  },
};

const StyledTypography = withStyles(baseStyle)(Typography);

const ItalicStyledTypography = withStyles({
  root: {
    ...baseStyle.root,
    fontStyle: 'italic',
  },
})(Typography);

const StyledTitle = withStyles({
  root: {
    ...baseStyle.root,
    fontSize: '1.5rem',
    textAlign: 'center',
  },
})(Typography);

const StyledDisclaimer = withStyles({
  root: {
    ...baseStyle.root,
    fontStyle: 'italic',
    textAlign: 'center',
  },
})(Typography);

export const Paused = ({
  onLeave,
  patron,
}: {
  onLeave: MouseEventHandler<HTMLElement>;
  patron: IPatron;
}) => {
  const t = useExtendedTranslation();

  // TODO: Deal with translation
  const meetingName = useMemo(() => {
    return patron.properties?.pausedMeetingName?.en;
  }, [patron]);

  return (
    <Box display="flex" flex={1} className="paused-meeting">
      <SinglePanelWithMessaging
        patron={patron}
        footer={
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <StyledDisclaimer variant="body2">
                {t('serviceDisclaimer')}
              </StyledDisclaimer>
            </Grid>
            <Grid item>
              <LeaveQueueButton variant="text" onClick={onLeave}>
                {t('thankyou.leave')}
              </LeaveQueueButton>
            </Grid>
          </Grid>
        }
      >
        <StyledTitle variant="h5">
          {t('meeting.paused.title.1')}
          <strong>{meetingName}</strong>
          {t('meeting.paused.title.2')}
        </StyledTitle>
        <Box flex={1} mt={2}>
          <StyledTypography>{t('meeting.paused.body1')}</StyledTypography>
          <br />
          <StyledTypography>{t('meeting.paused.body2')}</StyledTypography>
          <br />
          <ItalicStyledTypography>
            {t('meeting.paused.body3')}
          </ItalicStyledTypography>
        </Box>
      </SinglePanelWithMessaging>
    </Box>
  );
};
