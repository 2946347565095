import React, { MouseEventHandler } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';

import { SinglePanelWithMessaging, TextBlockDescription } from '@components';

import useClinicConfig from '@hooks/useClinicConfig';

import { useExtendedTranslation } from '@services/i18nService';

import IPatron from '@typings/IPatron';

import { formatPhoneNumber } from '@util/formatPhoneNumber';

import '@screens/CompleteSession/CompleteSession.scss';

function CompleteSession({
  patron,
  onComplete,
}: {
  patron: IPatron;
  onComplete: MouseEventHandler<HTMLElement>;
}) {
  const t = useExtendedTranslation();

  const clinicConfig = useClinicConfig();
  const hasPhoneExtension = !_.isEmpty(_.get(clinicConfig, 'phoneNoExt', ''));
  const formattedNumber = formatPhoneNumber(clinicConfig.phoneNo);

  return (
    <Box display="flex" flex={1} className="session-complete">
      <SinglePanelWithMessaging
        patron={patron}
        title={
          <Typography variant="h5" color="primary" align="center">
            {t('completeSession.title')}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={onComplete}>
                {t('questionnaire.close')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <TextBlockDescription>
          {t('completeSession.description.one')}
        </TextBlockDescription>

        <TextBlockDescription>
          {t('completeSession.description.three')}
        </TextBlockDescription>

        <TextBlockDescription>
          {t(
            hasPhoneExtension
              ? 'completeSession.description.four'
              : 'completeSession.description.fourNoExt',
            { phoneNo: formattedNumber, phoneNoExt: clinicConfig.phoneNoExt }
          )}
        </TextBlockDescription>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default CompleteSession;
