var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { raam } from 'raam-client-lib';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import { useMeetingStore } from './MeetingStore';
import { CAPTION_DISPLAY_TIMEOUT } from '../constants';
export const CaptionContext = createContext(undefined);
export function CaptionProvider({ children }) {
    const { meeting } = useDyteMeeting();
    const { roomConfig } = useMeetingStore();
    const [captions, setCaptions] = useState({});
    const [captionsHistory, setCaptionsHistory] = useState([]);
    const captionsRef = useRef({});
    const onCaptionToggle = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        try {
            yield raam.meetingRoom.setCaptioningState(meeting.meta.meetingId, meeting.self.customParticipantId, !roomConfig.isCaptioningEnabled);
        }
        catch (error) {
            console.error('Error occurred while toggling captioning', error);
        }
    }), [
        meeting.meta.meetingId,
        meeting.self.customParticipantId,
        roomConfig.isCaptioningEnabled,
    ]);
    const enqueueCaption = useCallback((broadcastData) => {
        const broadcastEntry = {
            name: broadcastData.name,
            caption: broadcastData.caption,
            timeStamp: broadcastData.timeStamp,
        };
        setCaptions((prev) => {
            return Object.assign(Object.assign({}, prev), { [broadcastData.id]: {
                    name: broadcastData.name,
                    caption: broadcastData.caption,
                } });
        });
        captionsRef.current = Object.assign(Object.assign({}, captionsRef.current), { [broadcastData.id]: broadcastEntry });
        // Note: consider if ongoing (live) caption should be added to history,
        // in this case, the final caption should replace the last live one (per id)
        if (broadcastData.saveToHistory && broadcastData.caption) {
            setCaptionsHistory((prev) => {
                // Note: consider using the timestamp if re-ordering is needed
                return [
                    ...prev,
                    {
                        id: broadcastData.id,
                        name: broadcastData.name,
                        caption: broadcastData.caption,
                        timeStamp: broadcastData.timeStamp,
                    },
                ];
            });
        }
    }, []);
    // Auto-remove captions that have expired
    useEffect(() => {
        const interval = setInterval(() => {
            const nextCaptions = {};
            const participantIds = Object.keys(captionsRef.current || {});
            participantIds.forEach((pid) => {
                const previousCaptionEntry = captionsRef.current[pid];
                if (previousCaptionEntry && previousCaptionEntry.timeStamp) {
                    // only keep still valid entries
                    if (previousCaptionEntry.timeStamp + CAPTION_DISPLAY_TIMEOUT >
                        Date.now()) {
                        nextCaptions[pid] = previousCaptionEntry;
                    }
                }
            });
            setCaptions(nextCaptions);
            captionsRef.current = nextCaptions;
        }, 0.25 * CAPTION_DISPLAY_TIMEOUT);
        return () => {
            clearInterval(interval);
        };
    }, []);
    const contextValue = useMemo(() => ({
        captions,
        captionsHistory,
        enqueueCaption,
        onCaptionToggle,
    }), [captions, captionsHistory, enqueueCaption, onCaptionToggle]);
    return (_jsx(CaptionContext.Provider, Object.assign({ value: contextValue }, { children: children })));
}
export function useCaption() {
    const context = useContext(CaptionContext);
    if (!context) {
        throw new Error('useCaption must be used within a CaptionProvider');
    }
    return context;
}
