import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteAudioVisualizer, DyteAvatar, DyteNameTag, DyteNetworkIndicator, DyteParticipantTile, } from '@dytesdk/react-ui-kit';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Fragment } from 'react/jsx-runtime';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useMemo } from 'react';
import _ from 'lodash';
import InQStyleGuide, { ACTIVE_SPEAKER_COLOUR, RAISED_HAND_COLOUR, } from '../../constants';
import { useLocale, useIsSpeaking } from '../../hooks';
import { HandRaisedIndicator } from '../HandRaisedIndicator/HandRaisedIndicator';
import { useCaption, useMeetingStore } from '../../context';
export var NameTagPosition;
(function (NameTagPosition) {
    NameTagPosition["LEFT"] = "left";
    NameTagPosition["CENTER"] = "center";
})(NameTagPosition || (NameTagPosition = {}));
const useStyles = makeStyles((theme) => ({
    tile: {
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
    },
    handRaised: {
        border: `${theme.spacing(0.375)}px solid ${RAISED_HAND_COLOUR} !important`,
        borderRadius: theme.spacing(2),
    },
    speakerTile: {
        border: `${theme.spacing(0.375)}px solid  ${ACTIVE_SPEAKER_COLOUR} !important`,
        borderRadius: theme.spacing(2),
    },
    nameTagWrapper: {
        alignItems: 'center',
        alignSelf: 'end',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: theme.spacing(1),
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        zIndex: 1,
    },
    nameTag: {
        background: 'none',
        color: InQStyleGuide.white,
    },
    fullTag: {
        width: '100%',
        borderRadius: 'none',
        margin: 0,
    },
    captionWrapper: {
        position: 'absolute',
        color: InQStyleGuide.white,
        top: 0,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: `${theme.spacing(1)}px`,
        margin: `${theme.spacing(1)}px`,
        marginRight: `${theme.spacing(8.25)}px`,
        fontSize: '1rem',
    },
}));
function NameTag({ meeting, participant, fullWidth, }) {
    const classes = useStyles();
    const languagePack = useLocale();
    return (_jsxs(Box, Object.assign({ className: clsx(classes.nameTagWrapper, {
            [classes.fullTag]: fullWidth,
        }) }, { children: [_jsx(DyteAudioVisualizer, { participant: participant }), _jsx(DyteNameTag, { participant: participant, meeting: meeting, className: classes.nameTag, t: languagePack }), _jsx(DyteNetworkIndicator, { meeting: meeting, participant: participant })] })));
}
export function ParticipantTile({ participant, className, fullWidthName, hideHandRaisedIcon = false, showCaptions = false, }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const { roomConfig: { handsRaised }, } = useMeetingStore();
    const { captions } = useCaption();
    const languagePack = useLocale();
    const isSpeaking = useIsSpeaking(participant);
    const isParticipantHandRaised = useMemo(() => { var _a; return (handsRaised === null || handsRaised === void 0 ? void 0 : handsRaised.includes((_a = participant.customParticipantId) !== null && _a !== void 0 ? _a : '')) || false; }, [handsRaised, participant.customParticipantId]);
    const caption = useMemo(() => {
        const captionEntry = captions[participant.id];
        return _.get(captionEntry, 'caption', '');
    }, [captions, participant.id]);
    return (_jsxs(Fragment, { children: [_jsxs(DyteParticipantTile, Object.assign({ meeting: meeting, participant: participant, t: languagePack, className: clsx({
                    [classes.handRaised]: isParticipantHandRaised,
                    [classes.speakerTile]: isSpeaking,
                }, classes.tile, className) }, { children: [_jsx(DyteAvatar, { participant: participant }), _jsx(NameTag, { meeting: meeting, participant: participant, fullWidth: fullWidthName })] }), participant.id), showCaptions && caption && (_jsx(Box, Object.assign({ className: classes.captionWrapper }, { children: caption }))), _jsx(HandRaisedIndicator, { handRaised: isParticipantHandRaised, hideIcon: hideHandRaisedIcon })] }, participant.id));
}
