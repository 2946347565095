var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { raam } from 'raam-client-lib';
import { SPEECH_TOKEN_KEY, STT_TOKEN_MAX_AGE_SEC } from '../constants';
import { getSessionItemWithExpiry, setSessionItemWithExpiry } from './utils';
export function getTokenOrRefresh(roomName) {
    return __awaiter(this, void 0, void 0, function* () {
        const speechToken = getSessionItemWithExpiry(SPEECH_TOKEN_KEY);
        if (!speechToken) {
            const { region, token } = yield raam.meetingRoom.getAzureSpeechToken(roomName);
            const authToken = String.fromCharCode(...token.data);
            setSessionItemWithExpiry(SPEECH_TOKEN_KEY, `${region}:${authToken}`, STT_TOKEN_MAX_AGE_SEC);
            return { authToken, region };
        }
        const [region, authToken] = _.split(speechToken, ':');
        return { authToken, region };
    });
}
