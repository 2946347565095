import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import {
  useAppState,
  useAsync,
  useEncryptedLocalStorage,
  useUAParser,
  useValidateQueryParams,
  useWindowDimensions,
} from '@hooks/index';
import { useFeatureFlags } from '@hooks/useFeatureFlags';

import {
  AboutInQ,
  ClosedQuestion,
  FrontPage,
  ManitobaRegion,
  ProvideInfo,
  RegionalEligibility,
  ScreenCheckIn,
  ScreenHealthCardCapture,
  ScreenHealthCardNeeded,
  ScreenManitobaHealthCardForm,
  ScreenMeetingConfirmation,
  ScreenOntarioHealthCardForm,
  ScreenPleaseWait,
  ScreenSomethingHappened,
  ScreenSubstanceUnsupported,
  ScreenSummary,
} from '@screens';

import { useExtendedTranslation } from '@services/i18nService';
import { getCurrentDateTime } from '@services/PatronService';

import { AppStates } from '@typings/IAppState';
import { DenialType, type ISurveyData } from '@typings/index';

import {
  DEFAULT_INIT_PATRON_VALUE as DEFAULT_USER_DATA,
  LOCAL_STORAGE_PROPERTIES,
  VCDS_LOCAL_STORAGE_PROPERTIES,
} from '@util/defaultValues';

enum Screen {
  Welcome = 'WELCOME',
  PatronInfo = 'PATRON INFO',
  RegionalInfo = 'REGIONAL INFO',

  FirstTimeQ = 'FIRST TIME QUESTION',
  ManitobaRegionQ = 'MANITOBA REGION QUESTION',
  HaveBookingQ = 'HAVE BOOKING QUESTION',
  AlcoholQ = 'ALCOHOL QUESTION',
  OpioidQ = 'OPIOID QUESTION',
  StimulantsQ = 'STIMULANTS QUESTION',
  OtherSubstancesQ = 'OTHER SUBSTANCES QUESTION',
  MentalHealthQ = 'MENTAL HEALTH QUESTION',

  MorePatronInfo = 'MORE PATRON INFO',

  HealthCardNeeded = 'HEALTH CARD NEEDED',
  HealthCardPhoto = 'HEALTH CARD PHOTO',
  HealthCardText = 'HEALTH CARD TEXT',

  ManitobaHealthCard = 'MANITOBA HEALTH CARD',

  Summary = 'SUMMARY',

  SubstanceUnsupported = 'SUBSTANCE UNSUPPORTED',
  PleaseWait = 'PLEASE WAIT',
  Error = 'ERROR',

  About = 'ABOUT',

  MeetingConfirmation = 'MEETING CONFIRMATION',
}

export default function SurveyFlow({
  onCheckIn,
  error,
  setError,
}: {
  onCheckIn: (
    newPatron: ISurveyData,
    initDateTime: string,
    denialType?: string
  ) => Promise<any>;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
}) {
  // States
  const [rememberedData, setRememberedData] = useEncryptedLocalStorage(
    'surveyData',
    DEFAULT_USER_DATA
  );
  const fromOFD = useValidateQueryParams({ check_in: 'true' });
  const [screen, setScreen] = useState<Screen>(
    fromOFD ? Screen.PatronInfo : Screen.Welcome
  );
  const [surveyData, setSurveyData] = useState<ISurveyData>(rememberedData);

  // Custom hooks
  const getCurrentDateTimeAsync = useAsync(getCurrentDateTime);
  const userAgentDetails = useUAParser();
  const windowDimension = useWindowDimensions();
  const {
    REACT_APP_USE_MANITOBA_HEALTHCARD,
    REACT_APP_IS_VCDS,
    REACT_APP_IS_DEBUG,
  } = useFeatureFlags();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const appState = useAppState();
  const t = useExtendedTranslation();

  // Derived states
  const initDateTime = _.get(
    getCurrentDateTimeAsync,
    ['value', 'details', 'message'],
    ''
  );

  // Functions
  const appendSurveyData = (data: any) => {
    const m = { ...surveyData, ...data };
    setSurveyData(m);
    return m;
  };
  const goTo = (newScreen: Screen) => () => setScreen(newScreen);
  const isOn = (expected: Screen) => screen === expected;
  const displayWhen = (expected: Screen, elements: JSX.Element) =>
    isOn(expected) && elements;
  const solveError = () => setError(false);
  const updateSurveyData = (data: any) => {
    appendSurveyData({
      ...data,
      isFirstTime: false,
      userAgent: userAgentDetails,
      screenWidth: windowDimension.width,
      screenHeight: windowDimension.height,
      screenOrientation:
        windowDimension.width > windowDimension.height
          ? 'landscape'
          : 'portrait',
    });
  };

  const handleRememberMe = (data: any) => {
    if (data.rememberMe) {
      setRememberedData({
        ...rememberedData,
        ..._.pick(
          data,
          REACT_APP_IS_VCDS
            ? VCDS_LOCAL_STORAGE_PROPERTIES
            : LOCAL_STORAGE_PROPERTIES
        ),
      });
    } else {
      localStorage.removeItem('surveyData');
    }
  };

  const handleWelcomeScreen = () =>
    getCurrentDateTimeAsync()
      .then(goTo(Screen.PatronInfo))
      .catch(goTo(Screen.Error));

  const handlePatronInfoScreenVCDS = (data: any) => {
    updateSurveyData(data);
    handleRememberMe(data);
    setScreen(Screen.MeetingConfirmation);
  };

  const handlePatronInfoScreen = (data: any) => {
    const { inRegion } = appendSurveyData(data);
    handleRememberMe(data);
    if (inRegion) {
      setScreen(Screen.FirstTimeQ);
    } else {
      _.flow([
        () => onCheckIn(surveyData, initDateTime, DenialType.SERVICE_AREA),
        goTo(Screen.SubstanceUnsupported),
      ])();
    }
  };

  useEffect(() => {
    if (fromOFD) {
      getCurrentDateTimeAsync()
        .then(goTo(Screen.PatronInfo))
        .catch(goTo(Screen.Error));
    }
  }, [getCurrentDateTimeAsync, fromOFD]);

  useEffect(() => {
    if (error) {
      setScreen(Screen.Error);
    }
    if (appState !== AppStates.OPEN && screen !== Screen.Welcome) {
      enqueueSnackbar(
        <Box>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', marginRight: '4px' }}
          >
            {t('error.queue.closed.snackbar')}
          </Typography>
        </Box>,
        {
          key: 'queue-closed',
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          preventDuplicate: true,
          persist: true,
          action: (key) => (
            <IconButton color="inherit" onClick={() => closeSnackbar(key)}>
              <CloseIcon />
            </IconButton>
          ),
        }
      );
      setScreen(Screen.Welcome);
    }
  }, [appState, enqueueSnackbar, closeSnackbar, t, screen, error]);

  if (REACT_APP_IS_VCDS) {
    return (
      <>
        {displayWhen(
          Screen.Welcome,
          <FrontPage appState={appState} onNext={handleWelcomeScreen} />
        )}

        {displayWhen(
          Screen.PatronInfo,
          <ScreenCheckIn onNext={handlePatronInfoScreenVCDS} />
        )}

        {displayWhen(
          Screen.MeetingConfirmation,
          <ScreenMeetingConfirmation
            message_key="checkin.meeting"
            onNext={_.flow([
              appendSurveyData,
              (data) => onCheckIn(data, initDateTime),
              goTo(Screen.PleaseWait),
            ])}
            unknownDefault={REACT_APP_IS_DEBUG}
          />
        )}

        {displayWhen(
          Screen.PleaseWait,
          <ScreenPleaseWait message_key="pleasewait.title" />
        )}

        {displayWhen(
          Screen.Error,
          <ScreenSomethingHappened
            onNext={_.flow([solveError, goTo(Screen.Welcome)])}
          />
        )}
      </>
    );
  }

  return (
    <>
      {displayWhen(
        Screen.Welcome,
        <FrontPage
          appState={appState}
          onNext={handleWelcomeScreen}
          onAbout={goTo(Screen.About)}
        />
      )}

      {displayWhen(
        Screen.PatronInfo,
        <ScreenCheckIn
          onNext={handlePatronInfoScreen}
          onRegion={_.flow([appendSurveyData, goTo(Screen.RegionalInfo)])}
        />
      )}

      {displayWhen(
        Screen.RegionalInfo,
        <RegionalEligibility onNext={goTo(Screen.PatronInfo)} />
      )}

      {displayWhen(
        Screen.FirstTimeQ,
        <ClosedQuestion
          questionKey="survey.question.firstTime"
          onYes={_.flow([
            () =>
              appendSurveyData({
                isFirstTime: true,
                userAgent: userAgentDetails,
                screenWidth: windowDimension.width,
                screenHeight: windowDimension.height,
                screenOrientation:
                  windowDimension.width > windowDimension.height
                    ? 'landscape'
                    : 'portrait',
              }),
            REACT_APP_USE_MANITOBA_HEALTHCARD
              ? goTo(Screen.ManitobaRegionQ)
              : goTo(Screen.AlcoholQ),
          ])}
          onNo={_.flow([
            () =>
              appendSurveyData({
                isFirstTime: false,
                userAgent: userAgentDetails,
                screenWidth: windowDimension.width,
                screenHeight: windowDimension.height,
                screenOrientation:
                  windowDimension.width > windowDimension.height
                    ? 'landscape'
                    : 'portrait',
              }),
            goTo(Screen.HaveBookingQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.ManitobaRegionQ,
        <ManitobaRegion
          onContinue={(shmbRegion: string) => {
            const survey = appendSurveyData({
              shmbRegion,
            });

            const nextScreenFn = survey.isFirstTime
              ? goTo(Screen.AlcoholQ)
              : goTo(Screen.HaveBookingQ);

            nextScreenFn();
          }}
        />
      )}

      {displayWhen(
        Screen.HaveBookingQ,
        <ClosedQuestion
          questionKey="survey.question.havebooking"
          onYes={_.flow([
            () => appendSurveyData({ haveAppointment: true }),
            (data) => onCheckIn(data, initDateTime),
            goTo(Screen.PleaseWait),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ haveAppointment: false }),
            (data) => onCheckIn(data, initDateTime),
            goTo(Screen.PleaseWait),
          ])}
        />
      )}

      {displayWhen(
        Screen.AlcoholQ,
        <ClosedQuestion
          questionKey="survey.question.useAlcohol"
          onYes={_.flow([
            () => appendSurveyData({ alcoholQ: true }),
            goTo(Screen.OpioidQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ alcoholQ: false }),
            goTo(Screen.OpioidQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.OpioidQ,
        <ClosedQuestion
          questionKey="survey.question.useOpioid"
          subKey="survey.question.opioid.small"
          onYes={_.flow([
            () => appendSurveyData({ opioidQ: true }),
            goTo(Screen.StimulantsQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ opioidQ: false }),
            goTo(Screen.StimulantsQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.StimulantsQ,
        <ClosedQuestion
          questionKey="survey.question.stimulantsOrAmphetamines"
          subKey="survey.question.stimulantsOrAmphetamines.small"
          onYes={_.flow([
            () => appendSurveyData({ stimulantsQ: true }),
            goTo(Screen.OtherSubstancesQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ stimulantsQ: false }),
            goTo(Screen.OtherSubstancesQ),
          ])}
        />
      )}

      {displayWhen(
        Screen.OtherSubstancesQ,
        <ClosedQuestion
          questionKey="survey.question.useOfOtherSubstances"
          onYes={_.flow([
            () => appendSurveyData({ otherSubstancesQ: true }),
            goTo(Screen.MentalHealthQ),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ otherSubstancesQ: false }),
            ({ alcoholQ, opioidQ, stimulantsQ }) => {
              if (alcoholQ || opioidQ || stimulantsQ) {
                setScreen(Screen.MentalHealthQ);
              } else {
                _.flow([
                  () =>
                    onCheckIn(
                      surveyData,
                      initDateTime,
                      DenialType.INVALID_ADDICTION
                    ),
                  goTo(Screen.SubstanceUnsupported),
                ])();
              }
            },
          ])}
        />
      )}

      {displayWhen(
        Screen.MentalHealthQ,
        <ClosedQuestion
          questionKey="survey.question.mentalHealth"
          onYes={_.flow([
            () => appendSurveyData({ mentalHealthQ: true }),
            goTo(Screen.MorePatronInfo),
          ])}
          onNo={_.flow([
            () => appendSurveyData({ mentalHealthQ: false }),
            goTo(Screen.MorePatronInfo),
          ])}
        />
      )}

      {displayWhen(
        Screen.MorePatronInfo,
        <ProvideInfo
          userData={surveyData}
          onNext={_.flow([
            appendSurveyData,
            goTo(
              REACT_APP_USE_MANITOBA_HEALTHCARD
                ? Screen.ManitobaHealthCard
                : Screen.HealthCardNeeded
            ),
          ])}
        />
      )}

      {displayWhen(
        Screen.HealthCardNeeded,
        <ScreenHealthCardNeeded
          onCamera={goTo(Screen.HealthCardPhoto)}
          onText={goTo(Screen.HealthCardText)}
        />
      )}

      {displayWhen(
        Screen.HealthCardPhoto,
        <ScreenHealthCardCapture
          onNext={_.flow([appendSurveyData, goTo(Screen.Summary)])}
          onBack={goTo(Screen.HealthCardNeeded)}
          onCameraError={goTo(Screen.HealthCardText)}
        />
      )}

      {displayWhen(
        Screen.HealthCardText,
        <ScreenOntarioHealthCardForm
          userData={surveyData}
          onNext={_.flow([appendSurveyData, goTo(Screen.Summary)])}
        />
      )}

      {displayWhen(
        Screen.ManitobaHealthCard,
        <ScreenManitobaHealthCardForm
          userData={surveyData}
          onNext={_.flow([appendSurveyData, goTo(Screen.Summary)])}
        />
      )}

      {displayWhen(
        Screen.Summary,
        <ScreenSummary
          userData={surveyData}
          setUserData={setSurveyData}
          onNext={_.flow([
            appendSurveyData,
            (data) => onCheckIn(data, initDateTime),
            goTo(Screen.PleaseWait),
          ])}
        />
      )}

      {displayWhen(
        Screen.SubstanceUnsupported,
        <ScreenSubstanceUnsupported onNext={goTo(Screen.Welcome)} />
      )}
      {displayWhen(
        Screen.PleaseWait,
        <ScreenPleaseWait message_key="pleasewait.title" />
      )}
      {displayWhen(
        Screen.Error,
        <ScreenSomethingHappened
          onNext={_.flow([solveError, goTo(Screen.Welcome)])}
        />
      )}

      {displayWhen(
        Screen.About,
        <AboutInQ onNext={_.flow([goTo(Screen.Welcome)])} />
      )}
    </>
  );
}
