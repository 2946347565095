import { useMemo, useEffect, useRef } from 'react';
import { raam } from 'raam-client-lib';
import { useImmer } from 'use-immer';
import _ from 'lodash';
import { DEFAULT_CAPTION_LANGUAGE_CODE } from '../constants';
const initRoomConfig = {
    meetingName: '',
    notificationId: '',
    roomLanguage: DEFAULT_CAPTION_LANGUAGE_CODE,
    participantsCaptioning: [],
    handsRaised: [],
    isCaptioningEnabled: false,
    isHandRaised: false,
};
export function useRoomConfig(meetingId, customParticipantId) {
    const [roomConfig, setRoomConfig] = useImmer(initRoomConfig);
    const isLoaded = useRef(false);
    const isCaptioningEnabled = useMemo(() => {
        if (!customParticipantId) {
            return false;
        }
        return _.includes(roomConfig.participantsCaptioning, customParticipantId);
    }, [customParticipantId, roomConfig.participantsCaptioning]);
    const isHandRaised = useMemo(() => {
        if (!customParticipantId) {
            return false;
        }
        return _.includes(roomConfig.handsRaised, customParticipantId);
    }, [customParticipantId, roomConfig.handsRaised]);
    const rc = useMemo(() => (Object.assign(Object.assign({}, roomConfig), { isCaptioningEnabled,
        isHandRaised })), [isCaptioningEnabled, isHandRaised, roomConfig]);
    useEffect(() => {
        const o$ = raam.meetingRoom.observeRoomConfig(meetingId);
        const subscription = o$.subscribe((v) => {
            setRoomConfig(v.properties);
            if (!isLoaded.current) {
                isLoaded.current = true;
            }
        }, (e) => {
            console.error('Error fetching room config', e);
            isLoaded.current = true;
        });
        return () => {
            subscription.unsubscribe();
            isLoaded.current = false;
        };
    }, [meetingId, setRoomConfig]);
    return { roomConfig: rc, hasLoadedInitially: isLoaded };
}
