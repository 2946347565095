import React, { MouseEventHandler } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import { SinglePanelLayout } from '@components';

import useClinicConfig from '@hooks/useClinicConfig';

import { useExtendedTranslation } from '@services/i18nService';

import IPatron from '@typings/IPatron';

import '@screens/Ejected/Ejected.scss';

function Ejected({
  patron,
  onClose,
}: {
  patron: IPatron;
  onClose: MouseEventHandler<HTMLElement>;
}) {
  const t = useExtendedTranslation();
  const just: any = patron.properties.justification;
  const clinicConfig = useClinicConfig();

  function decodeMessage(inputMessage: any) {
    return inputMessage.replaceAll('|', '\n');
  }
  return (
    <Box display="flex" flex={1} className="screen-ejected">
      <SinglePanelLayout
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <Button onClick={onClose} variant="contained" color="primary">
                {t('questionnaire.close')}
              </Button>
            </Grid>
          </Grid>
        }
        title={
          <Typography
            paragraph
            color="primary"
            align="center"
            style={{ fontSize: '24px' }}
          >
            {t('ejected.title')}
          </Typography>
        }
        logo={clinicConfig.logoName}
      >
        <Typography paragraph variant="body2" color="primary">
          {decodeMessage(just)}
        </Typography>
      </SinglePanelLayout>
    </Box>
  );
}

export default Ejected;
