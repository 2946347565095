import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DyteDialog, DyteSettingsAudio, DyteSettingsVideo, DyteSwitch, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import InQStyleGuide, { DEFAULT_CAPTION_LANGUAGE_CODE, PRESETS_CLINICIAN, } from '../../constants';
import { useMeetingStore } from '../../context';
import { useLocale } from '../../hooks';
const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'absolute',
        overflow: 'hidden',
    },
    dialogContent: {
        borderRadius: theme.spacing(1),
        backgroundColor: InQStyleGuide.black,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: '100%',
        width: theme.spacing(70),
    },
    dialogHeader: {
        padding: `0 ${theme.spacing(3)}px`,
    },
    dialogBody: {
        flex: 1,
        marginBottom: theme.spacing(3),
        overflow: 'auto',
        padding: `0 ${theme.spacing(3)}px`,
    },
    select: {
        backgroundColor: InQStyleGuide.grey.medium,
        borderRadius: theme.spacing(0.5),
        border: 'none',
        color: InQStyleGuide.white,
        fontSize: '16px',
        padding: theme.spacing(1.5),
        width: '100%',
    },
    switch: {
        boxSizing: 'border-box',
    },
}));
export default function SettingsDialog() {
    const { isBlurred, isMobile, isSettingsVisible, setHideSettings, toggleBlur, setVideoInput, setAudioInput, setAudioOutput, setRoomLanguage, roomConfig, } = useMeetingStore();
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const primaryCaptionLanguages = languagePack('PrimaryCaptionLanguages');
    const otherCaptionLanguages = languagePack('OtherCaptionLanguages');
    const primaryLanguages = useMemo(() => _.map(primaryCaptionLanguages, (entry) => {
        return (_jsx("option", Object.assign({ value: entry.locale }, { children: entry.languageName }), entry.locale));
    }), [primaryCaptionLanguages]);
    const otherLanguages = useMemo(() => _.map(otherCaptionLanguages, (entry) => {
        return (_jsx("option", Object.assign({ value: entry.locale }, { children: entry.languageName }), entry.locale));
    }), [otherCaptionLanguages]);
    const onBlurSwitchChange = useCallback((e) => {
        // Note: DyteSwitch calls this whenever switch value changes or initializes.
        // Make sure we react to direct user action only
        if (isSettingsVisible && e.detail !== isBlurred && toggleBlur) {
            toggleBlur();
        }
    }, [isBlurred, isSettingsVisible, toggleBlur]);
    const onDialogClose = useCallback(() => {
        setHideSettings();
    }, [setHideSettings]);
    const onChangeLanguage = (e) => {
        const nextLanguageCode = _.get(e, 'target.value', '');
        setRoomLanguage(nextLanguageCode);
    };
    // Handle audio/video input/output changes
    useEffect(() => {
        const handleDeviceChange = ({ device }) => {
            if (device.kind === 'audioinput') {
                setAudioInput(device.deviceId);
            }
            else if (device.kind === 'videoinput') {
                setVideoInput(device.deviceId);
            }
            else if (device.kind === 'audiooutput') {
                setAudioOutput(device.deviceId);
            }
        };
        meeting.self.on('deviceUpdate', handleDeviceChange);
        return () => {
            meeting.self.off('deviceUpdate', handleDeviceChange);
        };
    }, [meeting, setAudioInput, setAudioOutput, setVideoInput]);
    return (_jsx(DyteDialog, Object.assign({ className: classes.dialog, open: isSettingsVisible, onDyteDialogClose: onDialogClose }, { children: _jsxs(Box, Object.assign({ className: classes.dialogContent }, { children: [_jsxs(Box, Object.assign({ className: classes.dialogHeader }, { children: [_jsx("h2", { children: languagePack('settings') }), _jsx("hr", {})] })), _jsxs(Box, Object.assign({ className: classes.dialogBody }, { children: [_jsx("h3", { children: languagePack('video') }), _jsx(DyteSettingsVideo, { meeting: meeting, t: languagePack }), !isMobile && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsx("span", { children: languagePack('blur_toggle') }), _jsx(DyteSwitch, { className: classes.switch, checked: isBlurred, onDyteChange: onBlurSwitchChange })] }))), _jsx("hr", {}), _jsx("h3", { children: languagePack('audio') }), _jsx(DyteSettingsAudio, { meeting: meeting, t: languagePack }), _.includes(PRESETS_CLINICIAN, meeting.self.presetName) && (_jsxs(_Fragment, { children: [_jsx("hr", {}), _jsx("h3", { children: languagePack('captions') }), _jsx("span", { children: languagePack('captions_select') }), _jsx(Box, Object.assign({ display: "flex" }, { children: _jsxs("select", Object.assign({ className: classes.select, value: roomConfig.roomLanguage || DEFAULT_CAPTION_LANGUAGE_CODE, onChange: onChangeLanguage }, { children: [primaryLanguages, _jsx("hr", {}), otherLanguages] })) }))] }))] }))] })) })));
}
