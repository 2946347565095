import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ParticipantTile } from '../shared/ParticipantTile';
import InQStyleGuide from '../../constants';
import GridView from './GridView';
import { useHost } from '../../hooks/useHost';
import { useThirdSpeaker } from '../../hooks/useThirdSpeaker';
import { useMeetingStore } from '../../context';
import { CaptionDisplay } from '../CaptionDisplay/CaptionDisplay';
const useStyles = makeStyles((theme) => ({
    viewContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: theme.spacing(2),
        padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    },
    portraitViewContainer: {
        flexDirection: 'column',
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    landscapeViewContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    },
    participantsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        gap: theme.spacing(2),
    },
    portraitParticipantsContainer: {
        flexDirection: 'column',
    },
    tileWrapper: {
        position: 'relative',
        background: InQStyleGuide.grey.light,
        borderRadius: theme.spacing(2),
        flex: 1,
    },
    participantTile: {
        aspectRatio: 'unset',
        height: '100%',
    },
}));
export function MainParticipantsView({ isPortrait, isMobile, }) {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const hostSpeaker = useHost();
    const thirdSpeaker = useThirdSpeaker();
    const sharingScreen = useDyteSelector((m) => m.participants.joined.toArray().find((p) => p.screenShareEnabled));
    const { roomConfig: { isCaptioningEnabled }, } = useMeetingStore();
    if (!isMobile || sharingScreen) {
        return (_jsxs(Box, Object.assign({ className: clsx(classes.viewContainer, classes.portraitViewContainer) }, { children: [_jsx(GridView, {}), _jsx(CaptionDisplay, { isPortrait: isPortrait })] })));
    }
    return (_jsx(Box, Object.assign({ className: clsx(classes.viewContainer, {
            [classes.portraitViewContainer]: isPortrait,
            [classes.landscapeViewContainer]: !isPortrait,
        }) }, { children: _jsxs(Box, Object.assign({ className: clsx(classes.participantsContainer, {
                [classes.portraitParticipantsContainer]: isPortrait,
            }) }, { children: [_jsx(Box, Object.assign({ className: classes.tileWrapper }, { children: _jsx(ParticipantTile, { participant: meeting.self, className: classes.participantTile, hideHandRaisedIcon: true, showCaptions: isCaptioningEnabled }, meeting.self.id) })), hostSpeaker && meeting.self.id !== hostSpeaker.id && (_jsx(Box, Object.assign({ className: classes.tileWrapper }, { children: _jsx(ParticipantTile, { participant: hostSpeaker, className: classes.participantTile, showCaptions: isCaptioningEnabled }, hostSpeaker.id) }))), thirdSpeaker &&
                    thirdSpeaker.id !== meeting.self.id &&
                    thirdSpeaker.id !== (hostSpeaker === null || hostSpeaker === void 0 ? void 0 : hostSpeaker.id) && (_jsx(Box, Object.assign({ className: clsx(classes.tileWrapper) }, { children: _jsx(ParticipantTile, { participant: thirdSpeaker, className: classes.participantTile, showCaptions: isCaptioningEnabled }, thirdSpeaker.id) })))] })) })));
}
