import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import _fp from 'lodash/fp';
import clsx from 'clsx';
import { useCaption } from '../../context';
const useStyles = makeStyles((theme) => ({
    captionContainer: {
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: `${theme.spacing(1)}px`,
        position: 'absolute',
        bottom: 0,
        marginLeft: theme.spacing(27),
        marginRight: theme.spacing(1.5),
        fontSize: '16px',
    },
    captionContainerPortrait: {
        marginRight: theme.spacing(10),
    },
    participantEntry: {
        color: 'white',
        padding: '0 0.5rem',
    },
    name: {
        fontWeight: 'bold',
        marginRight: `${theme.spacing(1)}px`,
        whiteSpace: 'pre-wrap',
    },
    caption: {
        display: '-webkit-box',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        maxHeight: '3.6rem',
        whiteSpace: 'pre-line',
    },
}));
export function CaptionDisplay({ isPortrait }) {
    const classes = useStyles();
    const { captions } = useCaption();
    return (_jsx("div", Object.assign({ className: clsx(classes.captionContainer, {
            [classes.captionContainerPortrait]: isPortrait,
        }) }, { children: _fp.flow([
            _fp.toPairs,
            _fp.sortBy([0]),
            _fp.entries,
            _fp.map(([key, caption]) => {
                const name = _.get(caption, '1.name', '');
                const text = _.get(caption, '1.caption', '');
                return (_jsxs(Grid, Object.assign({ container: true, className: classes.participantEntry }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx("div", Object.assign({ className: classes.name }, { children: `${name}:` })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx("div", Object.assign({ className: classes.caption }, { children: text })) }))] }), key));
            }),
        ])(captions) })));
}
