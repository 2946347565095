import { useEffect, useState } from 'react';
import { useMeetingStore } from '../context';
import { DEFAULT_CAPTION_LANGUAGE_CODE } from '../constants';
import { useCCToken } from './useCCToken';
import { useSpeechRecognizer } from './useSpeechRecognizer';
/**
 * Transcribes audio of the current user locally, using Azure service
 * @param onTranscriptionReceived Callback function that will be called once there is a transcribed text
 */
export function useTranscription(onTranscriptionReceived) {
    const { isMicOn, audioInput, roomConfig } = useMeetingStore();
    const { ccToken } = useCCToken(roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.meetingName);
    const [languageCode, setLanguageCode] = useState((roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage) || DEFAULT_CAPTION_LANGUAGE_CODE);
    const [recognizerData, setRecognizerData] = useState({
        text: '',
        isFinal: false,
    });
    const recognizer = useSpeechRecognizer(ccToken, languageCode, audioInput, setRecognizerData);
    useEffect(() => {
        setLanguageCode(roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage);
    }, [roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.roomLanguage]);
    useEffect(() => {
        if (isMicOn && (roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participantsCaptioning.length)) {
            recognizer === null || recognizer === void 0 ? void 0 : recognizer.startContinuousRecognitionAsync();
            return;
        }
        recognizer === null || recognizer === void 0 ? void 0 : recognizer.stopContinuousRecognitionAsync();
    }, [recognizer, roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.participantsCaptioning, isMicOn, ccToken]);
    useEffect(() => {
        if (!isMicOn || !recognizerData || !onTranscriptionReceived) {
            return;
        }
        onTranscriptionReceived(recognizerData);
    }, [isMicOn, onTranscriptionReceived, recognizerData]);
}
