import { useEffect, useState } from 'react';

import { observe } from '@services/PatronService';

import { IPatronIds } from '@typings';
import IPatron from '@typings/IPatron';

const isValidPatron = (maybePatron: any) => {
  // Simple test to ensure we have the right instance
  if (
    maybePatron?.id &&
    maybePatron?.properties?.firstName &&
    maybePatron?.properties?.lastName
  ) {
    return true;
  }

  return false;
};

export function usePatron(
  patronIds: IPatronIds
): [IPatron | undefined, boolean] {
  const [patron, setPatron] = useState<IPatron>();
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    const o$ = observe(patronIds);

    const subscription = o$.subscribe(
      (v: any) => {
        if (v?.status === 404) {
          setNotFound(true);
        }

        if (isValidPatron(v)) {
          setPatron(v as IPatron);
        }
      },
      () => {}
    );

    return () => subscription.unsubscribe();
  }, [patronIds]);

  return [patron, notFound];
}
