import { useEffect, useState } from 'react';
import { STT_TOKEN_MAX_AGE_SEC } from '../constants';
import { getTokenOrRefresh } from '../utils/tokenUtil';
export function useCCToken(roomName) {
    const [ccToken, setCcToken] = useState();
    const [ccDisabled, setCcDisabled] = useState(false);
    useEffect(() => {
        if (!roomName) {
            return setCcToken(undefined);
        }
        const refreshFn = () => getTokenOrRefresh(roomName)
            .then((z) => setCcToken(z))
            .catch(() => setCcDisabled(true));
        refreshFn();
        const interval = setInterval(refreshFn, STT_TOKEN_MAX_AGE_SEC * 1000);
        return () => clearInterval(interval);
    }, [roomName]);
    return { ccToken, ccDisabled };
}
