import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DyteClient from '@dytesdk/web-core';
import _fp from 'lodash/fp';
import { raam } from 'raam-client-lib';
import { useMeetingClient } from 'raam-shared';

import { usePatronDisplayName } from '@hooks/usePatronDisplayName';

export enum MeetingViewEnum {
  InMeeting = 'IN_MEETING',
  InRejoin = 'IN_REJOIN',
}

const getDefaultDataContext = () => ({
  meeting: undefined,
  meetingViewState: MeetingViewEnum.InMeeting,
  setMeetingViewState: () => {},
});

export interface IMeetingContext {
  meeting: DyteClient | undefined;
  meetingViewState: MeetingViewEnum;
  setMeetingViewState: (state: MeetingViewEnum) => void;
}

const MeetingContext = createContext<IMeetingContext>(getDefaultDataContext());

export default MeetingContext;

const getTokenForMeeting = (
  meetingName?: string,
  patronId?: string,
  displayName?: string,
  meetingPin?: string
) => {
  if (meetingName && patronId && displayName) {
    return raam.patron.getRoomToken(
      displayName,
      patronId,
      meetingName,
      meetingPin || ''
    );
  }
};

function useMeeting(token: string) {
  const [meetingInternal, initMeeting] = useMeetingClient({
    resetOnLeave: true,
  });
  const initMeetingRef = useRef(initMeeting);
  const [disableOutput, setDisableOutput] = useState(false);

  useEffect(() => {
    let meetingPr: Promise<DyteClient> | undefined;
    if (initMeetingRef.current && token) {
      meetingPr = initMeetingRef
        .current({ authToken: token })
        .then(_fp.tap(() => setDisableOutput(false)))
        .catch((e) => {
          console.error('Error joining meeting', e);
          setDisableOutput(true);
        });
    }

    return () => {
      setDisableOutput(true);
      if (meetingPr) {
        meetingPr
          .then((mtg: DyteClient) => {
            mtg.leave();
          })
          .catch((e) => {
            console.error('Error leaving meeting', e);
          });
      }
    };
  }, [token]);

  // Dyte will set meetingInternal before it is actually ready to be used.
  // This ensure it only get set when the promise from the connection is resolved.
  const meeting = useMemo(() => {
    if (!disableOutput) {
      return meetingInternal;
    }
    return undefined;
  }, [disableOutput, meetingInternal]);

  // initMeeting is probably not wrapped properly in a useCallback inside the Dyte library
  // so this little piece of code makes that the effect on token does not get re-called
  // everytime it changes.
  useEffect(() => {
    initMeetingRef.current = initMeeting;
  }, [initMeeting]);

  return meeting;
}

type IToken = {
  token: string;
};

export function MeetingContextProvider({
  children,
  patron,
}: PropsWithChildren<any>): JSX.Element {
  const [token, setToken] = useState('');
  const [meetingViewState, setMeetingViewState] = useState<MeetingViewEnum>(
    MeetingViewEnum.InMeeting
  );
  const meeting = useMeeting(token);

  const meetingName = useMemo(() => {
    if (meetingViewState !== MeetingViewEnum.InMeeting) {
      return undefined;
    }

    switch (patron?.properties?.state) {
      case 'IN_MEETING':
      case 'IN_BREAKOUT':
        return patron.properties.meetingName;

      case 'IN_GROUP_MEETING':
        return patron.properties.groupMeetingName;
    }
  }, [
    meetingViewState,
    patron?.properties?.groupMeetingName,
    patron?.properties?.meetingName,
    patron?.properties?.state,
  ]);

  const patronDisplayName = usePatronDisplayName(patron);

  const prevPatronStateRef = useRef<string>('');
  useEffect(() => {
    if (prevPatronStateRef !== patron?.properties?.state) {
      setMeetingViewState((state) =>
        state === MeetingViewEnum.InMeeting ? state : MeetingViewEnum.InMeeting
      );
    }

    if (patron?.properties?.state) {
      prevPatronStateRef.current = patron?.properties?.state;
    }
  }, [patron?.properties?.state]);

  useEffect(() => {
    if (meetingName) {
      getTokenForMeeting(
        meetingName,
        patron?.id,
        patronDisplayName,
        patron?.properties?.meetingPin
      ).then((tok: IToken) => {
        setToken(tok.token);
      });
    } else {
      setToken('');
    }
  }, [
    meetingName,
    patron?.id,
    patronDisplayName,
    patron?.properties?.meetingPin,
  ]);

  const value = useMemo(
    () => ({
      meeting,
      meetingViewState,
      setMeetingViewState,
    }),
    [meeting, meetingViewState, setMeetingViewState]
  );

  return (
    <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>
  );
}
