import { MouseEventHandler } from 'react';
import React, { Box, Grid, Typography } from '@material-ui/core';

import { SinglePanelWithMessaging, TextBlockHint } from '@components';

import { useExtendedTranslation } from '@services/i18nService';

import IPatron from '@typings/IPatron';

function ValidatingDetail({
  onLeave,
  patron,
}: {
  onLeave: MouseEventHandler<HTMLElement>;
  patron: IPatron;
}) {
  const t = useExtendedTranslation();

  return (
    <Box display="flex" flex={1}>
      <SinglePanelWithMessaging
        patron={patron}
        title={
          <Typography variant="h5" color="primary" align="center" paragraph>
            {t('validating.title')}
          </Typography>
        }
        footer={
          <Grid container justifyContent="center">
            <Grid item>
              <TextBlockHint>
                <a href="# " onClick={onLeave}>
                  {t('validating.leave')}
                </a>
              </TextBlockHint>
            </Grid>
          </Grid>
        }
      >
        <Typography
          variant="body2"
          color="primary"
          align="center"
          style={{ fontStyle: 'italic' }}
          paragraph
        >
          {t('validating.description.one')}
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          align="center"
          style={{ fontStyle: 'italic' }}
          paragraph
        >
          {t('validating.description.two')}
        </Typography>
        <Typography
          variant="body2"
          color="primary"
          align="center"
          style={{ fontStyle: 'italic' }}
          paragraph
        >
          {t('validating.description.three')}
        </Typography>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default ValidatingDetail;
