import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useCaption } from '../../context';
const useStyles = makeStyles((theme) => ({
    captionContainer: {
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: `${theme.spacing(1)}px`,
        position: 'absolute',
        top: theme.spacing(2.5),
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(10),
        fontSize: '16px',
        maxHeight: theme.spacing(25),
        overflow: 'scroll',
    },
    participantEntry: {
        color: 'white',
        padding: '0 0.5rem',
    },
    name: {
        fontWeight: 'bold',
        marginRight: `${theme.spacing(1)}px`,
        whiteSpace: 'pre-wrap',
    },
    caption: {
        display: '-webkit-box',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        maxHeight: '3.6rem',
        whiteSpace: 'pre-line',
    },
}));
export function CaptionsHistory() {
    const classes = useStyles();
    const { captionsHistory } = useCaption();
    return (_jsx("div", Object.assign({ className: classes.captionContainer }, { children: _.map(captionsHistory, (caption, index) => {
            return (_jsxs(Grid, Object.assign({ container: true, className: classes.participantEntry }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx("div", Object.assign({ className: classes.name }, { children: `${caption.name}:` })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx("div", Object.assign({ className: classes.caption }, { children: caption.caption })) }))] }), index));
        }) })));
}
