import ReactHtmlParser from 'react-html-parser';
import { Button, Grid, Typography } from '@material-ui/core';
import i18next from 'i18next';

import useApplicationConfig from '@hooks/useApplicationConfig';

import { useExtendedTranslation } from '@services/i18nService';

function Rejoin({
  onContinue,
  onRejoin,
}: {
  onContinue: Function;
  onRejoin: Function;
}) {
  const t = useExtendedTranslation();
  const applicationConfig = useApplicationConfig();

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      spacing={1}
      className="rejoin"
    >
      <Grid item>
        <Typography paragraph variant="body2" color="primary">
          {ReactHtmlParser(
            applicationConfig.msgVisitComplete.primary[
              i18next.language as 'en' | 'fr'
            ]
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onContinue()}
        >
          {t('meeting.inProgress.continue')}
        </Button>
      </Grid>
      <Grid item>
        <Typography paragraph variant="body2" color="primary">
          {ReactHtmlParser(
            applicationConfig.msgVisitComplete.secondary[
              i18next.language as 'en' | 'fr'
            ]
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Button color="primary" variant="outlined" onClick={() => onRejoin()}>
          {t('meeting.inProgress.reconnect')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default Rejoin;
