import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';

import ErrorBoundary from '@components/ErrorBoundary';

import { DataContextProvider } from '@contexts/DataContext';

import useAsync, { AsyncStatus } from '@hooks/useAsync';

import en from '@i18n/en.json';
import fr from '@i18n/fr.json';

import { initializeHiveConnection } from '@services/HiveService';
import {
  init as initI18n,
  useExtendedTranslation,
} from '@services/i18nService';
import { getCurrentLanguage } from '@services/PatronService';
import { getTheme, init as initThemes } from '@services/ThemeService';

import DarkTheme from '@themes/darkTheme';
import LightTheme from '@themes/lightTheme';

import App from './App/App';
import * as serviceWorker from './serviceWorker';

import './index.scss';

initThemes(LightTheme, DarkTheme);
initI18n(
  { translation: { ...en.translation } },
  { translation: { ...fr.translation } }
);

const onError = () => <>Sorry, Something went wrong</>;

const Root = () => {
  const initializeHiveConnectionAsync = useAsync(initializeHiveConnection);

  const t = useExtendedTranslation();

  useEffect(() => {
    const lang = getCurrentLanguage() || 'en';
    document.documentElement.lang = lang;
  }, [t]);

  // Ensure this is called only once at the app bootUp
  useEffect(() => {
    initializeHiveConnectionAsync();
  }, [initializeHiveConnectionAsync]);

  return _.includes(
    [AsyncStatus.DONE, AsyncStatus.ERROR],
    initializeHiveConnectionAsync.status
  ) ? (
    <>
      <CssBaseline />
      <ErrorBoundary fallback={onError}>
        <Suspense fallback={<CircularProgress />}>
          <DataContextProvider>
            <App />
          </DataContextProvider>
        </Suspense>
      </ErrorBoundary>
    </>
  ) : (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={getTheme()}>
      <Root />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
