import { MouseEventHandler } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import i18next from 'i18next';

import { SinglePanelWithMessaging } from '@components';

import useApplicationConfig from '@hooks/useApplicationConfig';

import { useExtendedTranslation } from '@services/i18nService';

import IPatron from '@typings/IPatron';

import InQStyleGuide from '../../constants';

import '@screens/ThankYouForWaiting/ThankYouForWaiting.scss';

const LeaveQueueButton = withStyles({
  root: {
    padding: 0,
  },
  label: {
    color: InQStyleGuide.blue.bright,
    fontWeight: 600,
    textDecoration: 'underline',
    fontStyle: 'italic',
    textTransform: 'none',
    fontSize: '14px',
  },
})(Button);

const StyledDisclaimer = withStyles({
  root: {
    color: InQStyleGuide.blue.medium,
    fontStyle: 'italic',
    textAlign: 'center',
  },
})(Typography);

function ThankYouForWaiting({
  onLeave,
  patron,
}: {
  onLeave: MouseEventHandler<HTMLElement>;
  patron: IPatron;
}) {
  const theme = useTheme();
  const applicationConfig = useApplicationConfig();
  const t = useExtendedTranslation();

  return (
    <Box display="flex" flex={1} className="thank-you-for-waiting">
      <SinglePanelWithMessaging
        patron={patron}
        footer={
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <StyledDisclaimer variant="body2">
                {t('serviceDisclaimer')}
              </StyledDisclaimer>
            </Grid>
            <Grid item>
              <LeaveQueueButton variant="text" onClick={onLeave}>
                {t('thankyou.leave')}
              </LeaveQueueButton>
            </Grid>
          </Grid>
        }
      >
        <span style={{ color: theme.palette.primary.main }}>
          {ReactHtmlParser(
            applicationConfig.msgWaiting[i18next.language as 'en' | 'fr']
          )}
        </span>
      </SinglePanelWithMessaging>
    </Box>
  );
}

export default ThankYouForWaiting;
