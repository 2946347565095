const isFrozen = Symbol('Frozen');
/**
 * Prevents the object or it's descendants from being modified
 * @param obj The object to be frozen
 */
export function deepFreeze(obj) {
    if (typeof obj !== 'object' || !obj || obj[isFrozen]) {
        return obj;
    }
    // eslint-disable-next-line no-param-reassign
    obj[isFrozen] = true;
    Object.values(obj).forEach(deepFreeze);
    Object.freeze(obj);
    return obj;
}
/**
 * Applies given color to an svg icon string
 * @param svgIcon Svg icon string, with 'fill' set to 'currentColor' in it
 * @param color
 * @returns Changed svg icon string
 */
export function applyColor(svgIcon, color) {
    return svgIcon ? svgIcon.replace(/currentColor/g, color) : '';
}
const includesAny = (str, subs) => {
    return subs.some((v) => str.includes(v));
};
/**
 * Checks if a MediaDeviceInfo device is a front camera, according to it's label
 * (eng and fr supported)
 * @param device
 * @returns True if device is a front camera
 */
export const isFrontCamera = (device) => includesAny(device.label.toLowerCase(), ['front', 'avant']);
/**
 * Checks if a MediaDeviceInfo device is a back camera, according to it's label
 * (eng and fr supported)
 * @param device
 * @returns True if device is a back camera
 */
export const isBackCamera = (device) => includesAny(device.label.toLowerCase(), ['back', 'arrière', 'arriere']);
/**
 * Object providing Dyte video capabilities
 */
export const Video = {
    // true if the current browser is supported by Dyte
    isSupported: true, // TODO add logic here, if needed
};
// Returns true if 'participant' is same as 'speaker' and has mic turned on
export const isSpeaking = (participant, speaker) => participant &&
    participant.audioEnabled &&
    speaker &&
    speaker.id === participant.id;
/**
 * Saves a value into the session storage, attaching a given expiration time to it
 */
export const setSessionItemWithExpiry = (key, value, expiresInSeconds) => {
    const now = new Date();
    const expiryTimeMs = now.getTime() + expiresInSeconds * 1000;
    const itemData = {
        value,
        expiry: expiryTimeMs,
    };
    sessionStorage.setItem(key, JSON.stringify(itemData));
};
/**
 * Retrieves a value from the session storage that has an expiration time attached to it
 * If time expired, value is cleared and undefined is returned
 */
export const getSessionItemWithExpiry = (key) => {
    const item = sessionStorage.getItem(key);
    if (!item) {
        return undefined;
    }
    let itemData;
    try {
        itemData = JSON.parse(item);
    }
    catch (e) {
        console.warn(`Failed to parse sessionStorage item for key "${key}". Removing invalid item.`);
        sessionStorage.removeItem(key);
        return undefined;
    }
    // Check if the parsed item has both 'value' and 'expiry'
    if (!itemData ||
        typeof itemData.value === 'undefined' ||
        typeof itemData.expiry !== 'number') {
        console.warn(`Invalid sessionStorage item structure for key "${key}". Removing item.`);
        sessionStorage.removeItem(key);
        return undefined;
    }
    const now = new Date();
    if (now.getTime() > itemData.expiry) {
        // Data has expired, remove it from sessionStorage
        sessionStorage.removeItem(key);
        return undefined;
    }
    // Data still valid, return it
    return itemData.value;
};
