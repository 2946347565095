var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { BroadcastEventType, } from './useBroadcastedEvents';
/**
 * Handles sending of transcriptions between the meeting participants
 * @param meeting Dyte meeting object
 * @returns handler to be called when there is a transcribed text
 */
export function useBroadcastTranscription(meeting) {
    const onTranscriptionReceived = useCallback((recognizerData) => __awaiter(this, void 0, void 0, function* () {
        const broadcastData = {
            id: meeting.self.id,
            name: meeting.self.name,
            caption: recognizerData.text,
            timeStamp: Date.now(),
            saveToHistory: recognizerData.isFinal,
        };
        // Send the transcription to all participants
        // Note: could we send it only to the ones with CC turned on?
        try {
            yield meeting.participants.broadcastMessage(BroadcastEventType.LIVE_CAPTION, broadcastData);
        }
        catch (error) {
            console.error('Error occurred while broadcasting caption', error);
        }
    }), [meeting.participants, meeting.self.id, meeting.self.name]);
    return onTranscriptionReceived;
}
